import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ paginationData, setPaginationData, pages }) => {
  const pagesArr = new Array(pages || 4).fill(0).map((_, i) => i + 1);

  return (
    <div className="pagination my-auto">
      {paginationData.pageNumber !== 1 && (
        <button
          className="pagination-single-link fw-regular"
          onClick={() => {
            setPaginationData({ ...paginationData, currentPage: paginationData.currentPage - 1, pageNumber: paginationData.pageNumber - 1 });
            window.scrollTo({ top: 0 });
          }}
          disabled={paginationData.pageNumber === 1}
        >
          Önceki Sayfa
        </button>
      )}
      <div className="pagination-numbers fw-bold">
        {pagesArr?.map((page) => (
          <div
            className="pagination-numbers-item active"
            onClick={() => {
              setPaginationData({ ...paginationData, currentPage: page, pageNumber: page });
              window.scrollTo({ top: 0 });
            }}
            key={page}
          >
            {page}
          </div>
        ))}
      </div>
      {paginationData.pageNumber !== pagesArr.length && (
        <button
          className="pagination-single-link fw-regular"
          onClick={() => {
            setPaginationData({ ...paginationData, currentPage: paginationData.currentPage + 1, pageNumber: paginationData.pageNumber + 1 });
            window.scrollTo({ top: 0 });
          }}
        >
          Sonraki Sayfa
        </button>
      )}
    </div>
  );
};

export default Pagination;
