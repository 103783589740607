import React, { useState, useEffect } from 'react';
import { Accordion, Form, FormControl } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
//components
import '../../styles/components/_filter_accordion.scss';
import '../../styles/components/_filter.scss';
//

import { searchProject } from '../../redux/slices/ProjectSlice';

import { setFilter } from "../../redux/slices/filterSlice";

import { useHistory, useLocation } from 'react-router-dom';

import { selectDistricts, selectThemes, selectTarget, fetchDistricts, fetchThemes, fetchTarget } from '../../redux/slices/LooksupSlice';

import ProjectSlice, { getAllProjectsForSearching } from '../../redux/slices/ProjectSlice';
import { all } from 'axios';

const FilterAccordion = ({ setSearchedProjects, searchedProjects, checked }) => {
  const dispatch = useDispatch();

  const { projects } = useSelector((state) => state.projects);

  const { filter } = useSelector((state) => state.filter);

  // console.log('projects:', projects);

  const [item] = useState(['2022', '2023', '2024']);

  const [selectedDistricts, setSelectedDistricts] = useState(checked);

  const [selectedThemes, setSelectedThemes] = useState(checked);

  const [selectedTarget, setSelectedTarget] = useState(checked);

  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState('');

  //all selected reset effect
  useEffect(() => {
    // setSelectedYear(['2024']);
    // setSelectedThemes(checked);
    // setSelectedDistricts(checked);
    // setSelectedTarget(checked);
    dispatch(getAllProjectsForSearching())
    setSearchedProjects(allProjects);
  }, [checked]);

  useEffect(() => {
    // console.log(filter)
    if (filter['isFilter']) {
      // setSelectedTarget([])
      if (filter['themeFilter']?.length !== 0) {
        const themeTemp = []
        filter['themeFilter']?.forEach(id => {
          themeTemp.push(id)
        });
        setSelectedThemes(themeTemp)
      } 
      // console.log(selectedThemes)
      // setSelectedTarget([]);
      if (filter['targetFilter']?.length !== 0) {
        const targetTemp = []
        filter['targetFilter']?.forEach(id => {
          targetTemp.push(id)
        });
        setSelectedTarget(targetTemp)
    }
  } else {
    if ((filter['yilFilter']?.length && filter['themeFilter']?.length && filter['targetFilter']?.length) === 0) {
      setSelectedTarget([])
      setSelectedThemes([])
    }
  }
  }, [filter['isFilter']])

  const [districtsData, setDistrictsData] = useState([]);

  const [themesData, setThemesData] = useState([]);

  const [targetsData, setTargetsData] = useState([]);

  const [allProjects, setAllProjects] = useState([]);


  const [dataTEst, setdataTEst] = useState([]);

  const [searchedByYear, setSearchedByYear] = useState([]);

  //get all districts
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchDistricts());
      setDistrictsData(response.payload);
    };
    fetchData();
  }, [dispatch]);
  //get all themes
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchThemes());
      setThemesData(response.payload);
    };
    fetchData();
  }, [dispatch]);
  //get all targets
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchTarget());
      setTargetsData(response.payload);
    };

    fetchData();
  }, [dispatch]);

  // //getall projects to initializate
  // const gettingAllProjects = () => {
  //   const allProjects = projects?.reduce((acc, curr) => {
  //     acc.push(...curr.projects);
  //     return acc;
  //   }, []);
  //   return allProjects;
  // };
  //getall projects to initializate
  const gettingAllProjects = () => {
    const allProjects = projects?.reduce((acc, curr) => {
      acc.push(...curr.projects);
      return acc;
    }, []);
    return allProjects;
  };

  useEffect(() => {
    const allProjects = gettingAllProjects();
    allProjects && setAllProjects(allProjects);
    allProjects && setSearchedProjects(allProjects);
  }, [projects]);

  useEffect(() => {
    // handleSearchByYear();
  }, [allProjects]);

  const handleYearCheckboxChange = (year) => {
    if (!selectedYear.includes(year)) {
      setSelectedYear([...selectedYear, year]);
      // handleSearchByYear();
    } else {
      const newYears = selectedYear.filter((item) => item !== year);
      setSelectedYear([...newYears]);
    }

    handleFilterObject(selectedYear, selectedTarget, selectedThemes, false)
  };

  const handleThemesCheckboxChange = (id) => {
      if (selectedThemes.includes(id)) {
        setSelectedThemes(selectedThemes.filter((themeId) => themeId !== id));
      } else {
        // Eğer seçili değilse, listeye ekle
        setSelectedThemes([...selectedThemes, id]);
      }
      handleFilterObject(selectedYear, selectedTarget, selectedThemes, false)
  };

  const handleDistrictCheckboxChange = (id) => {
    if (selectedDistricts.includes(id)) {
      setSelectedDistricts(selectedDistricts.filter((districtId) => districtId !== id));
    } else {
      setSelectedDistricts([...selectedDistricts, id]);
    }
  };

  const handleFilterObject = (selectedYear, selectedTarget, selectedThemes, isFilter) => {
    dispatch(setFilter({'yilFilter': selectedYear, 'targetFilter': selectedTarget, 'themeFilter': selectedThemes, 'isFilter': isFilter}));
  }

  const handleTargetCheckboxChange = (id) => {
        if (selectedTarget.includes(id)) {
          setSelectedTarget(selectedTarget.filter((targetId) => targetId !== id));
        } else {
          setSelectedTarget([...selectedTarget, id]);
        }
        handleFilterObject(selectedYear, selectedTarget, selectedThemes, false)
      }

  // Searching..
  // useEffect(() => {
  //   handleSearchByYear();
  // }, [selectedYear]);

  // useEffect(() => {
  //   handleSearchByTheme();
  // }, [selectedThemes]);

  // useEffect(() => {
  //   handleSearchByDistricts();
  // }, [selectedDistricts]);

  // useEffect(() => {
  //   handleSearchByTarget();
  // }, [selectedTarget]);

  // const handleSearchByYear = () => {
  //   //years ['2022', '2023', '2024']
  //   if (selectedYear.length === 0) {
  //     setSearchedProjects(allProjects);
  //     return;
  //   }

  //   const srcByYear = selectedYear?.map((year) => allProjects?.filter((project) => project?.projectYear === Number(year)))?.flat();
  //   // console.log('srcByyear:', srcByYear);
  //   setSearchedProjects(srcByYear);
  //   setSearchedByYear(srcByYear);
  // };

  // const handleSearchByName = () => {
  //   if (selectedYear.length === 0) {
  //     setSearchedProjects(allProjects);
  //     return;
  //   }

  //   const srcByYear = selectedYear?.map((year) => allProjects?.filter((project) => project?.projectYear === Number(year)))?.flat();
  //   // console.log('srcByyear:', srcByYear);
  //   setSearchedProjects(srcByYear);
  //   setSearchedByYear(srcByYear);
  // };

  // const handleSearchByTheme = () => {
  //   let srchedThemes = [];
  //   if (selectedYear.length > 0) {
  //     handleSearchByYear();
  //     srchedThemes = searchedByYear?.filter((project) => selectedThemes?.includes(project?.projectTheme?.id));
  //   } else {
  //     handleSearchByYear();
  //     srchedThemes = allProjects?.filter((project) => selectedThemes?.includes(project?.projectTheme?.id));
  //   }
  //   // setSearchedByThemes(searchedProjects);
  //   // console.log('themesproject', srchedThemes);
  //   srchedThemes.length > 0 && setSearchedProjects(srchedThemes);
  // };

  // const handleSearchByDistricts = () => {
  //   let srchedDistricts = [];
  //   if (selectedYear.length > 0 && selectedThemes.length > 0) {
  //     handleSearchByTheme();
  //     srchedDistricts = searchedProjects?.filter((project) => selectedDistricts?.includes(project?.district?.id));
  //   } else if (selectedYear.length > 0) {
  //     handleSearchByYear();
  //     srchedDistricts = searchedProjects?.filter((project) => selectedDistricts?.includes(project?.district?.id));
  //   } else if (selectedThemes.length > 0) {
  //     handleSearchByTheme();
  //     srchedDistricts = searchedProjects?.filter((project) => selectedDistricts?.includes(project?.district?.id));
  //   } else {
  //     handleSearchByYear();
  //     srchedDistricts = allProjects?.filter((project) => selectedDistricts?.includes(project?.district?.id));
  //   }

  //   srchedDistricts.length > 0 && setSearchedProjects(srchedDistricts);
  // };

  // const handleSearchByTarget = () => {
  //   let srchedTargets = [];
  //   if (selectedYear.length > 0 && selectedThemes.length > 0 && selectedDistricts.length > 0) {
  //     // handleSearchByDistricts();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedYear.length > 0 && selectedThemes.length > 0) {
  //     // handleSearchByTheme();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedYear.length > 0 && selectedDistricts.length > 0) {
  //     // handleSearchByDistricts();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedThemes.length > 0 && selectedDistricts.length > 0) {
  //     // handleSearchByDistricts();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedYear.length > 0) {
  //     // handleSearchByYear();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedThemes.length > 0) {
  //     // handleSearchByTheme();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else if (selectedDistricts.length > 0) {
  //     handleSearchByDistricts();
  //     srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   } else {
  //     // handleSearchByYear();
  //     srchedTargets = allProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   }

  //   // const srchedTargets = searchedProjects?.filter((project) => selectedTarget?.includes(project?.targetGroupId));
  //   // console.log('srchedTargets:', srchedTargets);
  //   srchedTargets.length > 0 && setSearchedProjects(srchedTargets);
  // };

  
  const handleAllClear = () => {
    // console.log(filter)
    dispatch(setFilter({'yilFilter': [], 'targetFilter': [], 'themeFilter': [], 'isFilter': false}));
    setSelectedDistricts([]);
    setSelectedTarget([]);
    setSelectedThemes([]);
    setSelectedYear([]);
    setSelectedProjectName('')
  };

  // filtreleme butonu mini-app den alındı, güncellenecek...
  const handleFilter = async (e) => {
		e.preventDefault();

		const req = {
			projectName: selectedProjectName,
			projectYear: "2024",
			projectTargetGroups: 1,
      projectThemes: [""],
      sustainableFieldsOfActivity: [""]
		}
    dispatch(getAllProjectsForSearching(req))
  }

  const projectNameChange = (value) => {
    setSelectedProjectName(value);
  }

  // const isDataLoaded = themesData && targetsData && districtsData;

  // const filteredThemes = isDataLoaded ? themesData.filter(theme => selectedThemes.includes(theme.id)) : [];

  // const filteredTarget = isDataLoaded ? targetsData.filter(target => selectedTarget.includes(target.id)) : [];

  // const filteredDistricts = isDataLoaded ? districtsData.filter(districts => selectedDistricts.includes(districts.id)) : [];

  return (
    <>
      <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Proje Adı
            </Accordion.Header>
            <Accordion.Body>
              <Form.Control 
                type="text"
                name="projectName"
                placeholder='Lütfen Proje Adı Yazınız...'
                value={selectedProjectName} 
                onChange={(e) => projectNameChange(e.target.value)} />
              <Form.Control.Feedback type="invalid">Lütfen Boş Bırakmayınız.</Form.Control.Feedback>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> 
        <Accordion>
        <Accordion.Item>
          <Accordion.Header>Yıllar</Accordion.Header>
          <Accordion.Body>
            {item.map((item) => (
              <div className="form-check" key={item}>
                <label htmlFor={item} className="d-flex align-items-center justify-content-between form-check-label">
                  <input
                    type="checkbox"
                    id={item}
                    className="form-check-input"
                    onChange={(e) => handleYearCheckboxChange(e.target.value)}
                    value={item}
                    checked={selectedYear.includes(item)}
                  />
                  <span className="ms-2 fw-medium">{item}</span>
                  <span className="ms-auto text-secondary fw-regular" style={{ fontSize: '14px', color: '#666' }}></span>
                </label>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Hizmet Alanı</Accordion.Header>
          <Accordion.Body>
            {themesData &&
              themesData.map((theme) => (
                <div className="form-check" key={theme.id}>
                  <label htmlFor={theme.name} className="d-flex align-items-center justify-content-between form-check-label">
                    <input
                      type="checkbox"
                      id={theme.name}
                      className="form-check-input"
                      onChange={() => handleThemesCheckboxChange(theme.id)}
                      checked={selectedThemes.includes(theme.id)}
                      value={theme.id}
                    />
                    <span className="ms-2 fw-medium">{theme.name}</span>
                    <span className="ms-auto text-secondary fw-regular" style={{ fontSize: '14px', color: '#666' }}> 
                      {/* ({theme.projectCount}) */}
                    </span>
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> 
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>İlçeler</Accordion.Header>
          <Accordion.Body>
            {districtsData &&
              districtsData.map((districts) => (
                <div className="form-check" key={districts.id}>
                  <label htmlFor={districts.name} className="d-flex align-items-center justify-content-between form-check-label">
                    <input
                      type="checkbox"
                      id={districts.name}
                      className="form-check-input"
                      onChange={() => handleDistrictCheckboxChange(districts.id)}
                      checked={selectedDistricts.includes(districts.id)}
                      value={districts.id}
                    />
                    <span className="ms-2 fw-medium">{districts.name}</span>
                    <span className="ms-auto text-secondary fw-regular" style={{ fontSize: '14px', color: '#666' }}> 
                       {/* ({item.projectCount})  */}
                     </span>
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Hedef Grup</Accordion.Header>
          <Accordion.Body>
            {targetsData &&
              targetsData.map((target) => (
                <div className="form-check" key={target.id}>
                  <label htmlFor={target.name} className="d-flex align-items-center justify-content-start form-check-label">
                    <input
                      type="checkbox"
                      id={target.name}
                      className="form-check-input"
                      value={target.id}
                      onChange={() => handleTargetCheckboxChange(target.id)}
                      checked={selectedTarget.includes(target.id)}
                    />
                    <span className="ms-2 fw-medium">{target.name}</span>
                    {/* ... (other spans) */}
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <button className={`filter-clear-btn text-nowrap `} onClick={handleAllClear}>
        Seçimleri Temizle
      </button>
      <button className={`filter-clear-btn text-nowrap mt-3`} onClick={handleFilter}>
        Filtrele
      </button>
    </>
  );
};

export default FilterAccordion;
