import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getDataAPI2 } from '../../utility/fetchData';

// Asenkron işlem tanımlamaları

export const fetchDistricts = createAsyncThunk(
  '/fetchDistricts',

  async (_, thunkAPI) => {
    try {
      const response = await getDataAPI2('/Lookups/Districts');

      // console.log("1", response);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
);

export const fetchThemes = createAsyncThunk(
  '/fetchThemes',

  async (_, thunkAPI) => {
    try {
      const response = await getDataAPI2('/Lookups/Themes');

      // console.log("2", response);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
);

export const fetchTarget = createAsyncThunk(
  '/fetchTarget',

  async (_, thunkAPI) => {
    try {
      const response = await getDataAPI2('/Lookups/TargetGroups');

      // console.log("3", response);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
);

export const fetchApplicationTypes = createAsyncThunk(
  '/fetchApplicationTypes',

  async (_, thunkAPI) => {
    try {
      const response = await getDataAPI2('/Lookups/ApplicationTypes');

      // console.log("3", response);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
);

export const fetchSubThemes = createAsyncThunk(
  '/fetchSubThemes',

  async (id, thunkAPI) => {
    try {
      console.log(id)
      const res = await getDataAPI2(`Lookups/SubThemes?themeId=${id}`)
      return res;
    } catch (error) {
      console.error(error);
    }
  },
);

export const fetchNeighbourhoods = createAsyncThunk(
  '/fetchNeighbourhoods',

  async (id, thunkAPI) => {
    try {
      const response = await getDataAPI2(`/Lookups/Neighbourhoods?districtId=${id}`);

      // console.log("3", response);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
);

export const fetchTargetGroups = createAsyncThunk(
  '/fetchTargetGroups',

  async (_, thunkAPI) => {
    try {
      const response = await getDataAPI2('/Lookups/TargetGroups');

      // console.log("3", response);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
);

const initialState = {
  districts: [],
  targets: 0,
  themes: [],
  applicationTypes: [],
  subThemes: [],
  neighbourhoods: [],
  targetGroups: [],
  isLoading: false,
};

const LookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDistricts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDistricts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.districts = action.payload;
      })
      .addCase(fetchDistricts.rejected, (state) => {
        state.isLoading = false;
        state.districts = [];
      })
      .addCase(fetchThemes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchThemes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.themes = action.payload;
      })
      .addCase(fetchThemes.rejected, (state) => {
        state.isLoading = false;
        state.themes = [];
      })
      .addCase(fetchTarget.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTarget.fulfilled, (state, action) => {
        state.isLoading = false;
        state.targets = action.payload;
      })
      .addCase(fetchTarget.rejected, (state) => {
        state.isLoading = false;
        state.targets = [];
      })
      .addCase(fetchApplicationTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchApplicationTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.applicationTypes = action.payload;
      })
      .addCase(fetchApplicationTypes.rejected, (state) => {
        state.isLoading = false;
        state.applicationTypes = [];
      })
      .addCase(fetchSubThemes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubThemes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subThemes = action.payload;
      })
      .addCase(fetchSubThemes.rejected, (state) => {
        state.isLoading = false;
        state.subThemes = [];
      })
      .addCase(fetchNeighbourhoods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNeighbourhoods.fulfilled, (state, action) => {
        state.isLoading = false;
        state.neighbourhoods = action.payload;
      })
      .addCase(fetchNeighbourhoods.rejected, (state) => {
        state.isLoading = false;
        state.neighbourhoods = [];
      })
      .addCase(fetchTargetGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTargetGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.targetGroups = action.payload;
      })
      .addCase(fetchTargetGroups.rejected, (state) => {
        state.isLoading = false;
        state.targetGroups = [];
      });
  },
});

export const selectDistricts = (state) => state.districts;
export const selectThemes = (state) => state.themes;
export const selectTarget = (state) => state.targets;
export const selectApplicationTypes = (state) => state.applicationTypes;
export const selectSubThemes = (state) => state.subThemes;
export const selectNeighbourhoods = (state) => state.neighbourhoods;
export const selectTargetGroups = (state) => state.targetGroups;
export default LookupsSlice.reducer;
